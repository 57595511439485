if (!window.gcr_env) {
  window.gcr_env = {}
}

export const Consts = {
  // TODO: Use env.js instead
  ROOT_API: window.gcr_env.VUE_APP_ROOT_API || process.env.VUE_APP_ROOT_API,

  LOCAL_STORAGE_USER: 'user',
  LOCAL_STORAGE_USERNAME: 'username',
  LOCAL_STORAGE_REMEMBER_ME: 'remember-me',
  LOCAL_STORAGE_DEVICE: 'device',
  LOCAL_STORAGE_ACCREDITATION: 'accreditation',
  LOCAL_STORAGE_SACEMAIL: 'SACEmail',

  ERROR_INVALID_CREDENTIALS: 'Wrong username or password',
  ERROR_CREATEPASSWORD_ALREADYCREATED: 'AlreadyCreated',
  ERROR_CREATEPASSWORD_TOKENINVALID: 'Invalid',

  ACTION_USER_SETUSERSACEMAIL: 'user/setUserSACEmail',
  ACTION_USER_LOAD: 'user/load',
  ACTION_USER_LOGIN: 'user/login',
  ACTION_USER_REFRESHTOKEN: 'user/refreshtoken',
  ACTION_USER_GETTOKEN: 'user/gettoken',
  ACTION_USER_LOGOUT: 'user/logout',
  GETTER_USER_ISLOGGED: 'user/isLogged',
  GETTER_USER_EMAILSAC: 'user/emailSAC',
  GETTER_USER_ISSAC: 'user/isSAC',
  ACTION_ALERT_SHOW: 'alert/show',
  ACTION_ALERT_CLEAR: 'alert/clear',
  ACTION_ALERT_CLEAR_ALL_ERROR: 'alert/clearallerror',
  GETTER_ALERT_MESSAGES: 'alert/messages',
  ALERT_TYPE_INFO: 'info',
  ALERT_TYPE_NEW_VERSION: 'new-version',
  ALERT_TYPE_ERROR: 'error',
  ALERT_TYPE_NETWORK: 'network',
  ACTION_ACCREDITATION_SELECT: 'accreditation/setCurrentAccreditationId',
  ACTION_ACCREDITATION_LOAD: 'accreditation/load',
  GETTER_ACCREDITATION_CURRENT_ID: 'accreditation/currentAccreditationId',
  ACCREDITATION_STATUS_ACTIVE: 'ACTIVE',
  ACCREDITATION_STATUS_CLOSED: 'CLOSED',
  ACCREDITATION_STATUS_PENDING_RENEWAL: 'PENDING_RENEWAL',
  ACCREDITATION_STATUS_RENEWAL_IN_PROGRESS: 'RENEWAL_IN_PROGRESS',
  ACCREDITATION_RENEWAL_STATUS_COMPLETED: 'COMPLETED',
  ACCREDITATION_STATUS_RENEWAL_SUBMITTED: 'RENEWAL_SUBMITTED',
  ACCREDITATION_RENEWAL_STATUS_IMPEDED: 'IMPEDED',
  ACCREDITATION_RENEWAL_STATUS_PROCESSING: 'PROCESSING',
  ACCREDITATION_RENEWAL_STATUS_UNCONFIRMED: 'UNCONFIRMED',

  NEW_PROJECTS: 'NEW',
  ONGOING_PROJECTS: 'ONGOING',
  CLOSED_PROJECTS: 'CLOSED',
  SEARCH_PROJECTS: 'SEARCH',
  REGISTERED_PROJECTS: 'REGISTERED',
  REGISTERED_AS_RENTAL: 'REGISTERED_AS_RENTAL',
  SUBMITTED_PROJECTS: 'SUBMITTED',

  RENEWALL_DAYS_LIMIT: 60,

  USER_ROLES_CUSTOMER_SUPPORT: 'CustomerSupport',

  //45 secondes
  QUERY_TIMEOUT: 45000,
  //240 secondes -> 4 minutes
  UPLOAD_TIMEOUT: 240000
}
