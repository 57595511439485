import 'whatwg-fetch'
import 'custom-event-polyfill'
import '@/_helpers/sentry'

import Vue from 'vue'
import App from '@/App.vue'
import { Consts } from '@/_helpers'
import { router } from '@/_helpers/router'
import { apolloProvider } from '@/_helpers/apollo'
import i18n from '@/i18n'
import store from '@/_store'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip, {
  defaultTrigger: 'hover click'
})
Vue.prototype.$Consts = key => {
  return Consts[key]
}

Vue.config.productionTip = false

store.commit(Consts.ACTION_USER_LOAD)
store.commit(Consts.ACTION_ACCREDITATION_LOAD)

new Vue({
  router,
  apolloProvider,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
