import { Consts } from '@/_helpers/consts'
import { Guid } from '@/_helpers/guid'
import i18n from '@/i18n'

const MUTATION_ADDMESSAGE = 'addMessage'
const MUTATION_DELETEMESSAGE = 'deleteMessage'
const MUTATION_DELETEALLERRORS = 'deleteAllError'

const state = {
  messages: []
}

const getters = {
  messages: state => {
    return state.messages
  }
}

const actions = {
  async show({ commit, dispatch }, message) {
    message.id = Guid.generate()
    message.text = i18n.t(message.text)
    commit(MUTATION_ADDMESSAGE, message)

    if (message.type === Consts.ALERT_TYPE_INFO) {
      setTimeout(function() {
        dispatch('clear', message.id)
      }, 7000)
    }
  },
  async clear({ state, commit }, id) {
    let idx = state.messages
      .map(x => {
        return x.id
      })
      .indexOf(id)

    if (idx > -1) {
      commit(MUTATION_DELETEMESSAGE, idx)
    }
  },
  async clearallerror({ commit }) {
    commit(MUTATION_DELETEALLERRORS)
  }
}

const mutations = {
  addMessage(state, message) {
    let idx = state.messages
      .map(x => {
        return x.text
      })
      .indexOf(message.text)

    if (idx < 0) {
      state.messages.push(message)
    }
  },
  deleteMessage(state, index) {
    state.messages.splice(index, 1)
  },
  deleteAllError(state) {
    for (let i = state.messages.length - 1; i >= 0; --i) {
      if (state.messages[i].type === Consts.ALERT_TYPE_ERROR) {
        state.messages.splice(i, 1)
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
