<template>
  <div class="o-nav" :class="{ 'o-nav--toggled': isMenuDesktopToggled }">
    <div class="o-nav-top">
      <nav class="o-nav-top__left">
        <burger :toggleSideNav="toggleSideNav" />
        <img src="@/assets/logo_zone.svg" width="93" height="34" class="o-nav-top__logo" />
      </nav>
      <nav class="o-nav-top__right">
        <a v-if="currentCultureIsEnglish" class="o-nav-top__right-lang" @click="switchLang">{{
            $t('oppositeLanguageAbbr')
          }}</a>
        <div class="o-nav-top__right-select">
          <select-gcr
            :key="currentAccreditationKey"
            :value="currentAccreditation"
            :options="accreditationsOptions"
            @input="accreditationChange"
            :emptyValue=false
          />
        </div>
        <div class="o-nav-user" ref="o-nav-user" :class="{ 'o-nav-user--toggled': isUserToggled }">
          <button class="o-nav-user__btn" @click="userDropdownToggle"></button>
          <div class="o-nav-user__drop">
            <p class="o-nav-user__head" v-if="currentUser">
              <strong class="o-nav-user__head-name"> {{ currentUser.firstName }} {{ currentUser.lastName }} </strong>
              <span class="o-nav-user__head-email"> {{ currentUser.email }} </span>
            </p>
            <div @click="userDropdownClose">
              <router-link class="o-nav-user__link" active-class="o-nav-user__link--active" :to="profileUrl">
                {{ $t('myInformations') }}
              </router-link>
              <button class="o-nav-user__link" @click="logout">{{ $t('logout') }}</button>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div
      class="o-nav-side"
      :class="[{ 'o-nav-side--desktop-toggled': isMenuDesktopToggled }, { 'o-nav-side--mobile-toggled': isMenuMobileToggled }]"
    >
      <nav class="o-nav-side__select">
        <select-gcr
          :key="currentAccreditationKey"
          :value="currentAccreditation"
          :options="accreditationsOptions"
          @input="accreditationChange"
          :emptyValue=false
        />
      </nav>
      <nav class="o-nav-side__new-project" v-show="currentAccreditationIsActive">
        <router-link data-test-id="create-project-link" class="o-nav-side__new-project__btn" :to="urls.createProjectUrl">
          {{ $t('navSideNewProject') }}
        </router-link>
      </nav>
      <nav class="o-nav-side__main">
        <router-link
          :to="dashboardUrl"
          class="o-nav-side__main__btn o-nav-side__main__btn--dashboard"
          exact-active-class="o-nav-side__main__btn--active"
        >{{ $t('navSideDashboard') }}
        </router-link
        >
        <router-link
          :to="accreditationDetailUrl"
          class="o-nav-side__main__btn o-nav-side__main__btn--accreditations"
          active-class="o-nav-side__main__btn--active"
        >{{ $t('navSideAccreditations') }}
        </router-link
        >
        <router-link
          :to="projectsUrl"
          class="o-nav-side__main__btn o-nav-side__main__btn--projects"
          active-class="o-nav-side__main__btn--active"
        >{{ $t('navSideProjects') }}
        </router-link
        >
        <router-link
          :to="invoiceUrl"
          class="o-nav-side__main__btn o-nav-side__main__btn--invoices"
          active-class="o-nav-side__main__btn--active"
        >{{ $t('navSideInvoices') }}
        </router-link
        >
      </nav>
      <nav class="o-nav-side__secondary">
        <h3 class="o-nav-side__secondary__title">{{ $t('navSideMore') }}</h3>
        <a
          href="https://www.garantiegcr.com/fr/faq-la-zone-gcr/"
          class="o-nav-side__secondary__link o-nav-side__secondary__link--external2"
          target="_blank"
        >{{ $t('navSideFAQ') }}</a
        >
        <router-link
          data-test-id="form-list-link"
          v-show="currentAccreditationIsActive"
          :to="formListUrl"
          class="o-nav-side__secondary__link"
          active-class="o-nav-side__secondary__link--active"
        >{{ $t('navSideForm') }}
        </router-link
        >
        <router-link
          data-test-id="form-list-english-link"
          v-show="currentAccreditationIsActive"
          :to="formListUrlEnglish"
          class="o-nav-side__secondary__link"
          active-class="o-nav-side__secondary__link--active"
        >{{ $t('navSideFormEnglish') }}
        </router-link
        >
      </nav>
      <div class="o-nav-side__phone">
        <a href="https://www.garantiegcr.com/fr/contact/" class="o-nav-side__phone__link" target="_blank">{{
            $t('navSideContactUs')
          }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { Urls, Consts, User } from '@/_helpers'
import Burger from '@/components/atoms/Burger'
import SelectGcr from '@/components/atoms/Select'
import currentUserRoles from '@/graphql/users/getCurrentUserRoles.graphql'
import currentUserDetails from '@/graphql/users/getCurrentUserDetails.graphql'
import getAccreditationActive from '@/graphql/accreditations/getAccreditationActive.graphql'
import getAccreditations from '@/graphql/accreditations/getAccreditations.graphql'
import getAccreditationsByEmail from '@/graphql/accreditations/getAccreditationsByEmail.graphql'
import getUserByEmail from '@/graphql/users/getUserByEmail.graphql'
import { mapGetters, mapMutations } from 'vuex'
import i18n from '@/i18n'

require('../../utilities/form')

export default {
  components: { Burger, SelectGcr },
  data() {
    return {
      urls: {
        createProjectUrl: { name: Urls.CREATE_NEW_PROJECT }
      },
      dashboardUrl: { name: Urls.DASHBOARD },
      projectsUrl: { name: Urls.PROJECTS },
      loginUrl: { name: Urls.LOGIN },
      profileUrl: { name: Urls.USER_PROFILE },
      invoiceUrl: { name: Urls.INVOICE },
      inspectionsUrl: { name: Urls.INSPECTIONS },
      accreditationDetailUrl: { name: Urls.ACCREDITATION_DETAIL },
      formListUrl: { name: Urls.FORM_LIST },
      formListUrlEnglish: { name: Urls.FORM_LIST_ENGLISH },
      isMenuMobileToggled: false,
      isMenuDesktopToggled: false,
      isMenuMobileToggledBackup: false,
      isMenuDesktopToggledBackup: false,
      isMenuDesktopOverwriteMode: false,
      isUserToggled: false,
      currentAccreditationKey: 0,
      accreditationsOptions: [],
      currentUser: {},
      currentAccreditationIsActive: false,
      currentCultureIsEnglish: i18n.locale === 'en',
      isSAC: undefined
    }
  },
  apollo: {
    getCurrentUserRoles: {
      query: currentUserRoles,
      update(data) {
        this.isSAC = User.validateHaveCustomerSupport(data.currentUser.roles)
      }
    },
    getaccreditations: {
      query: getAccreditations,
      skip() {
        return this.isSAC
      },
      update(data) {
        this.accreditationsOptions = data.accreditations.map(this.mapAccreditation)
      }
    },
    getaccreditationsByEmail: {
      query: getAccreditationsByEmail,
      variables() {
        return {
          email: this.emailSAC
        }
      },
      skip() {
        return !this.isSAC
      },
      update(data) {
        this.accreditationsOptions = data.accreditationsByEmail.map(this.mapAccreditation)
      }
    },
    getcurrentaccreditation: {
      query: getAccreditationActive,
      variables() {
        return {
          id: this.currentAccreditation
        }
      },
      skip() {
        return this.currentAccreditation === null
      },
      update(data) {
        this.currentAccreditationIsActive = data.accreditation.isActive
        this.setAccreditationActive(data.accreditation.isActive)
      }
    },
    getcurrentuser: {
      query: currentUserDetails,
      skip() {
        return this.isSAC
      },
      update(data) {
        this.currentUser = data.currentUser
      }
    },
    getuserByEmail: {
      query: getUserByEmail,
      variables() {
        return {
          email: this.emailSAC
        }
      },
      skip() {
        return !this.isSAC
      },
      update(data) {
        this.currentUser = data.userByEmail
      }
    }
  },
  mounted() {
    this.userDropdownAddListener()
  },
  methods: {
    ...mapMutations({
      setCurrentAccreditationId: Consts.ACTION_ACCREDITATION_SELECT,
      setAccreditationActive: 'accreditation/setAccreditationActive'
    }),
    async logout() {
      await this.$store.dispatch(Consts.ACTION_USER_LOGOUT)
    },
    userDropdownToggle() {
      this.isUserToggled = !this.isUserToggled
    },
    userDropdownClose() {
      this.isUserToggled = false
    },
    userDropdownClickOutside(event) {
      // If open and the target is outside the dropdown element
      if (this.isUserToggled && !this.$refs['o-nav-user'].contains(event.target)) {
        this.userDropdownClose()
      }
    },
    userDropdownAddListener() {
      this.userDropdownRemoveListener()
      document.documentElement.addEventListener('click', this.userDropdownClickOutside)
    },
    userDropdownRemoveListener() {
      document.documentElement.removeEventListener('click', this.userDropdownClickOutside)
    },
    toggleSideNav() {
      if (window.innerWidth >= 1200) {
        this.isMenuDesktopToggled = !this.isMenuDesktopToggled
        this.isMenuMobileToggled = false
      } else {
        this.isMenuMobileToggled = !this.isMenuMobileToggled
        if (this.isMenuMobileToggled) {
          this.isMenuDesktopToggled = false
        }
      }
      if (!this.isMenuDesktopOverwriteMode) {
        this.isMenuDesktopToggledBackup = this.isMenuDesktopToggled
        this.isMenuMobileToggledBackup = this.isMenuMobileToggled
      }
    },
    accreditationChange(newAccreditation) {
      if (this.$router.currentRoute.meta.stayOnAccreditationChange) {
        this.setCurrentAccreditationId(newAccreditation)
      } else {
        if (!this.$router.currentRoute.meta.isForm || window.confirm(this.$t('confirmQuit'))) {
          this.setCurrentAccreditationId(newAccreditation)
          this.$router.push('/')
        } else {
          // Since the select has already changed, we force a re-render of the select by changing its key
          // Preventing the select from changing would be a better solution!
          this.currentAccreditationKey++
        }
      }
    },
    switchLang() {
      window.location.href = Urls.oppositeCurrentUrl(this.$route)
    },
    mapAccreditation(accreditation) {
      return {
        directText: `${accreditation.number} ${accreditation.company.name} ${
          accreditation.isActive ? '' : this.$t('deactivatedAccreditationLabel')
        }`,
        value: accreditation.id
      }
    }
  },
  computed: {
    ...mapGetters({
      emailSAC: Consts.GETTER_USER_EMAILSAC,
      currentAccreditation: Consts.GETTER_ACCREDITATION_CURRENT_ID
    })
  },
  watch: {
    $route(to, from) {
      if (this.$router.currentRoute.meta.isForm) {
        this.isMenuDesktopOverwriteMode = true
        this.isMenuDesktopToggled = false

        this.isMenuMobileToggled = true

        this.toggleSideNav()
      } else {
        this.isMenuDesktopOverwriteMode = false
        this.isMenuDesktopToggled = this.isMenuDesktopToggledBackup

        this.isMenuMobileToggled = this.isMenuMobileToggledBackup
      }
    }
  },
  beforeDestroy() {
    this.userDropdownRemoveListener()
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
@import '../../utilities/sass/mixins';

.o-nav {
  &.o-nav--cs {
    .o-nav-top {
      top: 90px;
    }

    .o-nav-side {
      top: 150px;
    }
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
  .o-nav {
    &.o-nav--cs {
      .o-nav-top {
        top: 40px;
      }

      .o-nav-side {
        top: 100px;
      }
    }
  }
}

.o-nav-top {
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: gray('white');
  box-shadow: 0px 7px 20px 0px rgba(gray('black'), 0.12);
  z-index: 1000;
  display: flex;
  justify-content: space-between;

  &__left {
    display: flex;
    height: 100%;
    margin: 0 15px;
    align-items: center;
  }

  &__logo {
    margin-left: 15px;
  }

  &__right {
    display: flex;
    height: 100%;
    margin: 0 15px 0 0;
    align-items: center;

    &-lang {
      align-self: center;
      align-items: center;
      color: gray('gray-700');
      cursor: pointer;
      display: flex;
      flex: 0 0 auto;
      font-weight: 500; //font-weight: 400;;
      height: 40px;
      justify-content: center;
      margin-top: 2px;
      text-align: center;
      text-decoration: none;
      width: 40px;
    }

    &-select {
      align-self: center;
      display: none;
      flex: 0 1 auto;
      padding: 0 12px;

      @media screen and (min-width: breakpoint-min('sm')) {
        display: block;
      }
    }
  }
}

.o-nav-user {
  align-self: stretch;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  position: relative;

  &__btn {
    @include btn-default-properties;
    background: url(../../assets/user_dark_gray.svg) no-repeat center center;
    background-size: 16px auto;
    display: block;
    flex: 0 0 auto;
    height: 40px;
    width: 40px;
  }

  &__drop {
    background: color('white');
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.14);
    display: none;
    max-width: calc(100vw - 30px);
    position: absolute;
    right: 0;
    top: 100%;
  }

  &--toggled &__drop {
    display: block;
  }

  &__head {
    background: gray('gray-100');
    margin: 0;
    padding: 20px;

    &-name {
      color: gray('gray-700');
      display: block;
      font-size: 17px;
      font-weight: 500;
    }

    &-email {
      color: gray('gray-400');
      display: block;
      font-size: 14px;
      letter-spacing: 0.05px;
      width: 100%;
      //ellipsis
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__link {
    @include btn-default-properties;
    border: 1px solid #dde2e8;
    display: block;
    font-size: 14px;
    font-weight: 500; //font-weight: 400;;
    padding: 10px 20px;
    text-align: left;
    white-space: nowrap;
    width: 100%;

    &:hover {
      text-decoration: underline;
    }

    &--active {
      color: gray('gray-400');
    }

    & + & {
      margin-top: -1px;
    }
  }
}

.o-nav-side {
  height: calc(100% - 60px);
  width: 66px;
  position: fixed;
  top: 60px;
  background-color: gray('gray-700');
  transition: width 0.5s ease, transform 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(-100%);
  z-index: 900;
  display: flex;
  flex-direction: column;

  &__select {
    margin: 20px 15px 0;
  }

  &__new-project {
    margin: 20px 15px 0;

    &__btn {
      display: block;
      color: color('white');
      padding: 7px 0px 7px 36px;
      text-align: left;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      text-decoration: none;
      background-color: theme-color('primary');
      border-radius: 18px;
      white-space: nowrap;
      width: 36px;
      overflow: hidden;
      transition: width 0.5s ease, background-color 0.5s ease, padding-left 1s ease;

      &::before {
        position: absolute;
        left: 18px;
        top: 18px;
        transform: translate(-50%, -50%);
        content: '';
        height: 13px;
        width: 13px;
        background-image: url(../../assets/add_white.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: darken(theme-color('primary'), 7.5%);
      }
    }
  }

  &__main {
    margin: 20px 15px 18px 0;

    &__btn {
      display: block;
      color: gray('gray-150');
      padding: 7px 0px 7px 47px;
      text-align: left;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      text-decoration: none;
      white-space: nowrap;
      border-left: 4px solid transparent;
      border-radius: 0 18px 18px 0;
      width: 36px;
      overflow: hidden;
      //@media screen and (min-width: breakpoint-min('md')) {
      //@media screen and (min-width: breakpoint-min('lg')) {
      transition: color 0.5s ease, width 0.5s ease, background-color 0.5s ease, padding-left 1s ease, border-color 0.5s ease;
      //}
      &::before {
        position: absolute;
        left: 29px;
        top: 18px;
        transform: translate(-50%, -50%);
        content: '';
        height: 18px;
        width: 18px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &--dashboard {
        &::before {
          background-image: url(../../assets/dashboard_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/dashboard_green.svg);
          }
        }
      }

      &--projects {
        &::before {
          background-image: url(../../assets/home_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/home_green.svg);
          }
        }
      }

      &--invoices {
        &::before {
          background-image: url(../../assets/invoice_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/invoice_green.svg);
          }
        }
      }

      &--accreditations {
        &::before {
          background-image: url(../../assets/accreditation_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/accreditation_green.svg);
          }
        }
      }

      &--inspections {
        &::before {
          background-image: url(../../assets/check_circle_gray.svg);
        }

        &.o-nav-side__main__btn--active {
          &::before {
            background-image: url(../../assets/check_circle_green.svg);
          }
        }
      }

      &--active {
        border-left: 4px solid theme-color('primary');
        background-color: theme-color('light-green');
        color: theme-color('secondary');
      }

      &:hover {
        background-color: gray('gray-600');
        border-left: 4px solid gray('gray-400');

        &.o-nav-side__main__btn--active {
          border-left: 4px solid theme-color('primary');
          background-color: theme-color('light-green');
          color: theme-color('secondary');
        }
      }
    }
  }

  &__secondary {
    display: block;
    margin-top: auto;
    border-top: 1px solid gray('gray-600');
    padding: 25px 15px 25px 25px;

    // Required for perfect width when scrollbar is shown
    width: 100%;

    // Hotfix to prevent text compression (without preventing line return)
    // when the menu display only icon
    min-width: 220px;

    &__title {
      color: gray('gray-400');
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &__link {
      display: block;
      font-size: 15px;
      color: gray('gray-150');
      font-weight: 500; //font-weight: 400;;
      margin: 15px 0;
      text-decoration: none;

      &[target='_blank'] {
        &:after {
          content: '';
          display: inline-block;
          height: 0.875em;
          width: 0.875em;
          background-image: url(../../assets/external_link_white.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          margin-left: 0.5em;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &--active,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__phone {
    border-top: 1px solid gray('gray-600');
    padding: 14px 0;

    &__link {
      display: block;
      color: gray('gray-150');
      padding: 7px 0px 7px 51px;
      text-align: left;
      font-weight: 500;
      font-size: 15px;
      position: relative;
      text-decoration: none;
      white-space: nowrap;
      width: 36px;
      overflow: hidden;
      transition: color 0.5s ease, width 0.5s ease, padding-left 1s ease;

      &::before {
        position: absolute;
        left: 33px;
        top: 18px;
        transform: translate(-50%, -50%);
        content: '';
        height: 18px;
        width: 18px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../../assets/telephone_gray.svg);
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &--mobile-toggled {
    transform: translateX(0);
    width: 240px;
    box-shadow: 7px 0px 20px 0px rgba(gray('black'), 0.25);

    .o-nav-side__new-project {
      &__btn {
        width: 100%;
        padding: 7px 18px 7px 44px;
      }
    }

    .o-nav-side__main {
      &__btn {
        width: 100%;
        padding: 7px 18px 7px 55px;
      }
    }

    .o-nav-side__phone {
      &__link {
        width: 225px;
        padding: 7px 18px 7px 59px;
      }
    }
  }

  @media screen and (min-width: breakpoint-min('sm')) {
    transform: translateX(0);

    &__select {
      display: none;
    }

    &__secondary {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity 0.5s ease, max-height 1s cubic-bezier(0, 1, 0.5, 1);
    }

    &--mobile-toggled {
      width: 240px;
      box-shadow: 7px 0px 20px 0px rgba(gray('black'), 0.25);

      .o-nav-side__new-project {
        &__btn {
          width: 100%;
          padding: 7px 18px 7px 44px;
        }
      }

      .o-nav-side__main {
        &__btn {
          width: 100%;
          padding: 7px 18px 7px 55px;
        }
      }

      .o-nav-side__secondary {
        max-height: 500px;
        opacity: 1;
        overflow: visible;
      }

      .o-nav-side__phone {
        &__link {
          width: 225px;
          padding: 7px 18px 7px 59px;
        }
      }
    }
  }

  @media screen and (min-width: breakpoint-min('lg')) {
    width: 240px;
    box-shadow: none;
    &__new-project {
      &__btn {
        width: 210px;
        padding: 7px 18px 7px 44px;
      }
    }
    &__main {
      &__btn {
        width: 225px;
        padding: 7px 18px 7px 55px;
      }
    }
    &__secondary {
      max-height: 500px;
      opacity: 1;
      overflow: visible;
      transition: opacity 0.5s ease, max-height 1s cubic-bezier(0, 1, 0.5, 1);
    }
    &__phone {
      &__link {
        width: 225px;
        padding: 7px 18px 7px 59px;
      }
    }

    &--desktop-toggled {
      width: 66px;

      .o-nav-side__new-project {
        &__btn {
          width: 36px;
          padding-left: 36px;
          padding-right: 0;
          overflow: hidden;
        }
      }

      .o-nav-side__main {
        &__btn {
          width: 36px;
          padding-left: 47px;
          padding-right: 0;
          overflow: hidden;
        }
      }

      .o-nav-side__secondary {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
      }

      .o-nav-side__phone {
        &__link {
          width: 36px;
          padding-left: 51px;
          padding-right: 0;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
