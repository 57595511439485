<template>
  <div class="o-nav-loggedout">
    <div class="container-fluid">
      <nav class="row align-items-center">
        <div class="col col-md-5 offset-md-1 o-nav-loggedout__col-backlink">
          <a class="btn btn-outline-gray btn-sm o-nav-loggedout__backlink" :href="siteUrl" rel="nofollow">{{
            $t('backtosite')
          }}</a>
        </div>
        <div class="col col-md-5 o-nav-loggedout__col-lang">
          <a class="o-nav-loggedout__langlink o-nav-loggedout__langlink--loggedout" v-if="isLogged" v-on:click="logout">{{
            $t('logout')
          }}</a>
          <a v-if="currentCultureIsEnglish" class="o-nav-loggedout__langlink" v-on:click="switchLang">{{
            $t('oppositeLanguage')
          }}</a>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Urls, Consts } from '@/_helpers'
import i18n from '@/i18n'

export default {
  data: function() {
    return {
      dashboardUrl: Urls.localizedUrl(Urls.DASHBOARD),
      siteUrl: 'https://www.garantiegcr.com/fr/',
      currentCultureIsEnglish: i18n.locale === 'en'
    }
  },
  computed: {
    ...mapGetters({ isLogged: Consts.GETTER_USER_ISLOGGED })
  },
  methods: {
    switchLang() {
      window.location.href = Urls.oppositeCurrentUrl(this.$route)
    },
    logout: async function() {
      await this.$store.dispatch(Consts.ACTION_USER_LOGOUT)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
@import 'node_modules/bootstrap/scss/buttons';

.o-nav-loggedout {
  position: absolute;
  width: 100%;
  z-index: 100;
  padding-top: 30px;
  &__col-lang {
    text-align: right;
  }
  &__backlink {
    //border-color: ;
    //background-color: blue;
    color: gray('gray-750');
    background-color: gray('white');
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    padding: 7px 17px 7px 9px;

    &:hover,
    &:focus,
    &:active {
      background-color: gray('gray-100');
    }

    &::before {
      content: '';
      //color: gray('white');
      display: inline-block;
      margin-right: 8px;
      //height: 17px;
      height: 1em;
      width: 6px;
      background-image: url(../../assets/arrow_left_gray.svg);
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin-top: 0.5px;
    }
  }
  &__langlink {
    color: gray('gray-700');
    text-decoration: none;
    font-weight: 500; //font-weight: 400;;
    cursor: pointer;
    display: inline-block;
    & + & {
      margin-left: 15px;
    }
    &--loggedout {
      display: none;
      @media screen and (min-width: breakpoint-min('xs')) {
        display: inline-block;
      }
    }
    &:hover,
    &:focus,
    &:active {
      color: darken(gray('gray-700'), 15%);
      text-decoration: underline;
    }
  }
}
</style>
