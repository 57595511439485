<template>
  <div class="m-alert">
    <div class="row">
      <div class="col col-xs-10 offset-xs-1">
        <div
          v-for="message in messages"
          :key="message.id"
          class="m-alert__ribbon"
          :class="alertClass(message)"
          @click="messageClick(message)">
          <div class="m-alert__message">{{ message.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Consts } from '@/_helpers'

export default {
  computed: {
    ...mapGetters({ messages: Consts.GETTER_ALERT_MESSAGES })
  },
  methods: {
    alertClass: (message) => `m-alert__ribbon--${message.type}`,
    messageClick(message) {
      this.$store.dispatch(Consts.ACTION_ALERT_CLEAR, message.id)
      if (message.type === Consts.ALERT_TYPE_NEW_VERSION)
      {
        location.reload();
        return false
      }
    }
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch(Consts.ACTION_ALERT_CLEAR_ALL_ERROR)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

@keyframes alertSlideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.m-alert {
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 100;

  &--cs {
    top: 150px;
    @media screen and (min-width: breakpoint-min('sm')) {
      top: 100px;
    }
  }

  &--loggedout {
    top: 0;
    z-index: 200;

    &.m-alert--cs {
      top: 70px;
      @media screen and (min-width: breakpoint-min('sm')) {
        top: 40px;
      }
    }
  }

  &__ribbon {
    display: flex;
    background-color: theme-color('warning');
    justify-content: space-between;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    margin-top: -7px;
    position: relative;
    cursor: pointer;

    box-shadow: 0px 5px 10px 0px rgba(gray('black'), 0.18);

    animation-name: alertSlideDown;
    animation-timing-function: ease;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @for $i from 0 through 24 {
      &:nth-child(#{$i}) {
        animation-delay: calc(#{$i} / 2) s;
        z-index: calc(25 - #{$i});
      }
    }

    &::before {
      content: '';
      display: block;
      background-image: url(../../assets/warning_circle_white.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 16.5px;
      min-width: 16.5px;
      max-width: 16.5px;
      height: 16.5px;
      min-height: 16.5px;
      min-width: 16.5px;
      margin: 19px 0 0 15px;
    }
    &::after {
      content: '';
      display: block;
      background-image: url(../../assets/close_white.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 10.5px;
      height: 10.5px;
      margin: 24px 15px 0 0;
    }
    &--info {
      background-color: theme-color('success');
      &::before {
        background-image: url(../../assets/check_circle_white.svg);
      }
    }
    &--new-version {
      background-color: theme-color('primary');
      &::before {
        background-image: url(../../assets/refresh_white.svg);
      }
    }
    &--error {
      background-color: theme-color('danger');
      &::before {
        background-image: url(../../assets/error_circle_white.svg);
      }
    }
    &--network {
      background-color: theme-color('danger');
      &::before {
        background-image: url(../../assets/network_problem_white.svg);
      }
    }
  }
  &__message {
    color: gray('white');
    font-size: 14px;
    font-weight: 500; //font-weight: 400;;
    margin: 18px 15px 11px;
    text-align: center;
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
  .m-alert {
    width: calc(100% - 66px);
    left: 66px;

    &--loggedout {
      width: 100%;
      left: 0;
    }
  }
}
@media screen and (min-width: breakpoint-min('lg')) {
  .m-alert {
    width: calc(100% - 240px);
    left: 240px;

    &--loggedout {
      width: 100%;
      left: 0;
    }
  }
}
</style>
