import ApolloLinkTimeout from 'apollo-link-timeout'
import { Consts } from '@/_helpers/consts'
import { onError } from '@apollo/client/link/error'
import store from '@/_store'
import { createHttpLink } from '@apollo/client/link/http'
import { graphqlService } from '@/_services/graphql.service'
import { ApolloClient } from '@apollo/client/core'
import { ApolloLink } from '@apollo/client/link/core'
import { InMemoryCache } from '@apollo/client/cache'
import VueApollo from 'vue-apollo'
import Vue from 'vue'

Vue.use(VueApollo)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.error(graphQLErrors, getErrors(networkError)) // eslint-disable-line

  if (graphQLErrors) {
    graphQLErrors.map(({ extensions }) => {
      if (extensions.data && extensions.data.PasswordMismatch) {
        store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'passwordMismatch' })
      } else {
        store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_ERROR, text: 'unexpectedError' })
      }
    })
  }

  if (networkError) {
    store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_NETWORK, text: 'networkError' })
  }
})

const httpLink = createHttpLink({ uri: `${Consts.ROOT_API}/graphql`, fetch: graphqlService.graphQLFetcher })

const timeoutLink = new ApolloLinkTimeout(Consts.QUERY_TIMEOUT)

const timeoutHttpLink = timeoutLink.concat(httpLink)

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, timeoutHttpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
    query: {
      fetchPolicy: 'cache-and-network'
    }
  }
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

function getErrors(error) {
  if (!error) {
    return error
  }
  return error.errors ?? error
}
