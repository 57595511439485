import store from '@/_store'
import { Consts } from '@/_helpers/consts'
import currentVersion from '../../public/version.json'

export const graphqlService = {
  graphQLFetcher
}

function graphQLFetcher(uri, params) {
  // NOTE: Why do we retry the query on errors?
  return fetchWithRefresh().catch(e => fetchWithRefresh())

  function fetchWithRefresh() {
    return doFetch()
      .then(handleRefresh)
      .then(handleFeatures)
      .then(handleVersionMismatch)
      .then(handleErrors)
  }

  async function handleRefresh(response) {
    if (response.status === 401) {
      await store.dispatch(Consts.ACTION_USER_REFRESHTOKEN)
      response = await doFetch()
      if (response.status === 401) {
        throw new Error('Unauthorized')
      }
    }
    return response
  }

  async function handleErrors(response) {
    let responseJson = await response.clone().json()
    if (responseJson.errors) {
      throw new AggregateError(responseJson.errors.map(e => new Error(`${e.extensions?.code}: ${e.message}`)))
    }
    return response
  }

  function handleFeatures(response) {
    if (response.headers.has('zone-features')) {
      const features = response.headers.get('zone-features')
        .split(',')
        .filter(f => f !== '')
      store.commit('features/load', features)
    }
    return response
  }

  async function handleVersionMismatch(response) {
    if (isRunningOnADevWorkstation()) return response

    let backendVersion = response.headers.get('app-version')
    if (versionMismatch(backendVersion) && versionMismatch(await getDeployedVersion()))
      store.dispatch(Consts.ACTION_ALERT_SHOW, { type: Consts.ALERT_TYPE_NEW_VERSION, text: 'newVersionAvailable' })

    return response
  }

  function isRunningOnADevWorkstation() {
    return currentVersion.Version === 'local-version'
  }

  function versionMismatch(version) {
    return version !== currentVersion.Version
  }

  function getDeployedVersion() {
    return fetch(window.location.origin + '/version.json')
      .then(r => r.json())
      .then(v => v.Version)
  }

  async function doFetch() {
    const token = await store.dispatch(Consts.ACTION_USER_GETTOKEN)
    if (token) {
      params.headers.authorization = `Bearer ${token}`
    }
    return fetch(uri, params)
  }
}
