import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import alert from './modules/alert'
import accreditation from './modules/accreditation'
import features from './modules/features'
import form from './modules/form'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { user, alert, accreditation, features, form }
})

export default store
