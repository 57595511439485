import i18n from '@/i18n'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'

dayjs.extend(advancedFormat)
dayjs.extend(utc)

export const Dates = {
  diffDays,
  formatDays,
  formatDate,
  getFormFormatDate,
  initDate,
  isPast,
  isBefore,
  isBeforeOrEqual,
  getYear
}

function diffDays(firstDate, secondDate) {
  if (!firstDate || !secondDate) {
    return ''
  }
  firstDate = dayjs(firstDate).toDate()
  secondDate = dayjs(secondDate).toDate()

  const oneDay = 24 * 60 * 60 * 1000
  return Math.floor(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay))
}

function formatDays(days) {
  if (days !== '') {
    if (days === 0) {
      return i18n.t('today')
    } else if (days === 1) {
      return i18n.t('yesterday')
    } else {
      return i18n.t('someDays').replace('$1', days)
    }
  } else {
    return ''
  }
}

function initDate(date) {
  if (!date) {
    return date
  }
  return dayjs.utc(date).toDate()
}

function formatDate(date) {
  if (!date) {
    return date
  }
  if (i18n.locale === 'en') {
    return dayjs(date, 'YYYY-MM-DD').locale('en').format('MMM Do YYYY')
  } else {
    return dayjs(date, 'YYYY-MM-DD').locale('fr').format('D MMM YYYY')
  }
}

function getFormFormatDate(date) {
  if (!date) {
    return date
  }
  return dayjs.utc(date).format('YYYY-MM-DD')
}

function isPast(firstDate) {
  return isBefore(firstDate, new Date())
}

function isBefore(firstDate, secondDate) {
  return !isBeforeOrEqual(secondDate, firstDate)
}

function isBeforeOrEqual(firstDate, secondDate) {
  const first = dayjs(firstDate)
  const second = dayjs(secondDate)
  if (!first.isValid() || !second.isValid()) throw new Error('Invalid date')

  return first <= second
}

function getYear(date) {
  return dayjs(date).get('year')
}
