import { Consts } from '@/_helpers/consts'
import storage from '@/_helpers/storage'

const state = {
  currentAccreditationId: null,
  isActive: null
}

const getters = {
  currentAccreditationId: state => {
    return state.currentAccreditationId
  }
}

const mutations = {
  setCurrentAccreditationId(state, currentAccreditationId) {
    state.currentAccreditationId = currentAccreditationId
    storage.setValue(Consts.LOCAL_STORAGE_ACCREDITATION, currentAccreditationId)
  },
  setAccreditationActive(state, isActive) {
    state.isActive = isActive
  },
  load(state) {
    const accreditationId = storage.getValue(Consts.LOCAL_STORAGE_ACCREDITATION)
    if (validateAccreditationId(accreditationId))
      state.currentAccreditationId = accreditationId
  }
}

function validateAccreditationId(accreditationId) {
  if (!accreditationId) return false
  try {
    if (atob(accreditationId).startsWith('Accreditation:')) {
      return true
    }
  } catch {}
  console.error('Invalid stored accreditation: %s', accreditationId)
  return false
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
