import { Consts } from '@/_helpers/consts'

export const userService = {
  createpassword,
  createpasswordchecktoken,
  createpasswordresend,
  forgotpassword,
  resetpassword,
  login,
  refreshtoken
}

function createpasswordchecktoken(emailaddress, token) {
  return post(`${Consts.ROOT_API}/account/createpassword/checktoken`, { emailaddress, token })
}

function createpasswordresend(emailaddress) {
  return post(`${Consts.ROOT_API}/account/createpassword/resend`, { emailaddress })
}

function createpassword(emailaddress, token, password) {
  return post(`${Consts.ROOT_API}/account/createpassword`, { emailaddress, token, password })
}

function forgotpassword(emailaddress) {
  return post(`${Consts.ROOT_API}/account/forgotpassword`, { emailaddress })
}

function resetpassword(emailaddress, token, newpassword) {
  return post(`${Consts.ROOT_API}/account/resetpassword`, { emailaddress, token, newpassword })
}

function login(username, password, deviceid) {
  return post(`${Consts.ROOT_API}/token/login`, { username, password, deviceid })
}

function refreshtoken(token, refreshtoken, deviceid) {
  return post(`${Consts.ROOT_API}/token/refresh`, { token, refreshtoken, deviceid })
}

function post(url, body) {
  return fetch(url, postBody(body)).then(handleResponse)
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) throw getError(data, response)
    return data
  })
}

function getError(data, response) {
  return (data && (data.message || data.title)) || response.statusText || (data.errors && data.errors[0].code)
}

function postBody(body) {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  }
}
