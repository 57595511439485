<template>
  <button class="a-burger" v-on:click="onclick"></button>
</template>

<script>
export default {
  props: ['toggleSideNav'],
  methods: {
    onclick(evt) {
      this.toggleSideNav()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
.a-burger {
  display: block;
  border-radius: 36px;
  border: 0;
  cursor: pointer;
  background-color: theme-color('faded-green');
  background-image: url(../../assets/burger_gray.svg);
  background-size: 16px 12.4px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 36px;
  height: 36px;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    background-color: gray('gray-100');
  }
}
</style>
