const state = {
  isDirty: false
}

const mutations = {
  setDirty(state, isDirty) {
    state.isDirty = isDirty
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
