import i18n from '@/i18n'

export const Cash = {
  format
}

function format(value) {
  const formatter = new Intl.NumberFormat(`${i18n.locale}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 2
  })

  return formatter.format(value)
}
