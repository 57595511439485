import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/i18n'
import { Urls } from './urls'
import { Consts } from './consts'
import store from '@/_store'

Vue.use(Router)

export const router = new Router({
  forceRefresh: true,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    // stayOnAccreditationChange: Si true alors on permet de rester sur cette page si l'usager change d'accréditation
    // Il faut le permettre seulement pour les page qui ne concernent pas un projet en particulier,
    // car ce projet n'existe pas dans la nouvelle accérditation

    // isForm: A comme effet de demander une confirmation si l'usager quitte la page sans avoir sauvegardé
    // et collapse le menu de gauche pour concentrer l'usager sur son formulaire
    { path: '/', redirect: { name: Urls.DASHBOARD } },
    { path: '/404', component: () => import('@/views/NotFound404.vue') },
    { path: '*', redirect: '/404' },
    {
      ...route(Urls.DASHBOARD),
      component: () => import('@/views/Dashboard.vue'),
      meta: { stayOnAccreditationChange: true }
    },
    {
      ...route(Urls.LOGIN),
      component: () => import('@/views/Login.vue'),
      meta: { public: true }
    },
    {
      ...route(Urls.CONFIRM_EMAIL),
      component: () => import('@/views/CreatePassword.vue'),
      meta: { public: true }
    },
    {
      ...route(Urls.RESET_PASSWORD),
      component: () => import('@/views/ResetPassword.vue'),
      meta: { public: true }
    },
    {
      ...route(Urls.FORGOT_PASSWORD),
      component: () => import('@/views/ForgotPassword.vue'),
      meta: { public: true }
    },
    {
      ...route(Urls.USER_PROFILE),
      component: () => import('@/views/UserProfile.vue'),
      meta: { isForm: true }
    },
    {
      ...route(Urls.USER_PROFILE_SAC),
      component: () => import('@/views/UserProfile.vue'),
      meta: { isForm: true },
      props: { userIsSac: true }
    },
    {
      ...route(Urls.SELECT_ACCREDITATIONS),
      component: () => import('@/views/SelectAccreditation.vue')
    },
    {
      ...route(Urls.CREATE_NEW_PROJECT),
      component: () => import('@/views/CreateNewProject.vue'),
      meta: { isForm: true, stayOnAccreditationChange: true }
    },
    {
      ...route(Urls.EDIT_NEW_PROJECT),
      component: () => import('@/views/EditNewProject.vue'),
      props: route => ({ projectId: route.query.id }),
      meta: { isForm: true, stayOnAccreditationChange: true }
    },
    {
      ...route(Urls.PROJECT_DETAILS),
      component: () => import('@/components/project/ProjectDetails.vue'),
      props: route => ({ projectId: route.query.id, currentTab: route.query.ct })
    },
    {
      ...route(Urls.EDIT_BUILDING_CONSTRUCTION_SCHEDULE),
      component: () => import('@/views/EditBuildingConstructionSchedule.vue'),
      props: route => ({ projectId: route.query.projectId, buildingId: route.query.buildingId }),
      meta: { isForm: true }
    },
    {
      ...route(Urls.REGISTER_PROJECT),
      component: () => import('@/components/building/RegisterSingleBuildingProject'),
      meta: { isForm: true },
      props: route => ({ projectId: route.query.projectId, buildingId: route.query.buildingId })
    },
    {
      ...route(Urls.EDIT_BUILDING),
      component: () => import('@/components/building/EditBuildingMultibuildingProject'),
      meta: { isForm: true },
      props: route => ({ projectId: route.query.projectId, buildingId: route.query.buildingId, copy: route.query.copy })
    },
    {
      ...route(Urls.PROJECTS),
      component: () => import('@/views/Projects.vue'),
      meta: { stayOnAccreditationChange: true }
    },
    {
      ...route(Urls.PAY_PROJECT),
      component: () => import('@/views/PayProject.vue')
    },
    {
      ...route(Urls.INVOICE),
      component: () => import('@/views/Invoice.vue'),
      meta: { stayOnAccreditationChange: true }
    },
    {
      ...route(Urls.INSPECTIONS),
      component: () => import('@/views/Inspections.vue'),
      meta: { stayOnAccreditationChange: true }
    },
    {
      ...route(Urls.FORM_LIST),
      component: () => import('@/views/FormList.vue'),
      meta: { stayOnAccreditationChange: true }
    },
    {
      ...route(Urls.FORM_LIST_ENGLISH),
      component: () => import('@/views/FormListEnglish.vue'),
      meta: { stayOnAccreditationChange: true }
    },
    {
      ...route(Urls.ACCREDITATION_DETAIL),
      component: () => import('@/views/AccreditationDetail.vue'),
      meta: {}
    },
    {
      ...route(Urls.ACCREDITATION_RENEW),
      component: () => import('@/views/AccreditationRenew.vue'),
      meta: { isForm: true }
    },
    {
      ...route(Urls.BUYERS_INFO),
      component: () => import('@/views/UpdateBuyersInfo.vue'),
      meta: { isForm: true }
    }
  ]
})

function route(name) {
  return {
    name,
    path: Urls.localizedUrl(name),
    alias: Urls.oppositeLocalizedUrl(name)
  }
}

function setLocaleFromRoute(to) {
  if (to.path === '/en' || to.path.includes('/en/')) {
    i18n.locale = 'en'
  } else {
    i18n.locale = 'fr'
  }
}

function hasAccreditation() {
  return store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID]
}

function requiresAccreditation(to) {
  return to.name !== Urls.SELECT_ACCREDITATIONS
}

function cancelDirtyForm() {
  if (store.state.form.isDirty && !window.confirm(i18n.t('confirmQuit')))
    return true

  store.commit('form/setDirty', false)
  return false
}

router.beforeEach((to, from, next) => {
  if (cancelDirtyForm())
    return next(false)

  setLocaleFromRoute(to)

  const loggedIn = store.getters[Consts.GETTER_USER_ISLOGGED]

  if (to.meta.public) {
    return loggedIn ? next('/') : next()
  }

  if (!loggedIn) {
    return next({
      name: Urls.LOGIN,
      query: { returnPath: to.fullPath }
    })
  }

  if (!hasAccreditation() && requiresAccreditation(to)) {
    return next({
      name: Urls.SELECT_ACCREDITATIONS,
      query: { returnPath: to.fullPath }
    })
  }

  next()
})
