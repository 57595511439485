import Vue from 'vue'
import VueI18n from 'vue-i18n'
import env from '@/_helpers/env'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: {
    fr: require('./locales/fr.json')
  }
})

export default i18n
