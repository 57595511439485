<template>
  <div class="a-select">
    <label v-if="label" :for="id">
      <span v-html="$t(label)" />
      <template v-if="tooltip">&nbsp;<info :tooltip="tooltip" @click.native.prevent />
      </template>
    </label>
    <span class="a-select__select">
      <select :id="id" ref="input" v-bind="$attrs" class="form-control" :class="{ 'is-invalid': invalid }" v-model="selected">
        <option v-if="emptyValue" disabled value="">{{ $t('select') }}</option>
        <option v-for="option in options" v-bind:value="option.value" :key="option.value" :disabled="option.disabled">
          {{ option.directText || $t(option.text) }}
        </option>
      </select>
    </span>
    <div v-show="invalid" class="invalid-feedback">{{ $t(invalidText) }}</div>
    <div v-if="validationFeedback" class="invalid-feedback html5-validation">{{ validationFeedback }}</div>
  </div>
</template>

<script>
import Info from '@/components/atoms/Info'

export default {
  components: { Info },
  inheritAttrs: false,
  props: {
    invalidText: {},
    label: {},
    tooltip: {},
    value: {},
    options: {},
    submitted: {},
    id: {
      default() {
        return `select-${this._uid}`
      }
    },
    emptyValue: {
      type: Boolean,
      default() {
        return true
      }
    },
    validationFeedback: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    invalid() {
      return this.invalidText && this.submitted && !this.value
    },
    selected: {
      get() {
        return this.value === null || this.value === undefined ? '' : this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    validationFeedback(message) {
      this.$refs.input.setCustomValidity(message)
    }
  },
  mounted() {
    this.$refs.input.setCustomValidity(this.validationFeedback)
  }
}
</script>
<style lang="scss" scoped>
.a-select {
  &__select {
    position: relative;
    display: block;

    select.form-control {
      cursor: pointer;
      appearance: none;

      &::placeholder {
        color: gray('gray-150');
        font-weight: 300;
      }
    }

    &::after {
      background: white;
      border-radius: 0 6px 6px 0;
      content: '';
      width: 43px;
      height: calc(100% - 4px);
      position: absolute;
      right: 2px;
      top: 2px;
      background-image: url(../../assets/chevron_down_dark_green.svg);
      background-size: 15px auto;
      background-repeat: no-repeat;
      background-position: center center;
      pointer-events: none;
    }
  }
}

.invalid-feedback.html5-validation {
  display: none;
}

.was-validated .invalid-feedback.html5-validation {
  display: block;
}
</style>
