import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { CaptureConsole, Vue as VueIntegration } from '@sentry/integrations'
import env from '@/_helpers/env'
import version from '../../public/version.json'
import store from '@/_store'
import { decodeId } from '@/_helpers/graphql'

Sentry.init({
  dsn: env.VUE_APP_SENTRY_DSN,
  integrations: [
    new VueIntegration({ Vue, attachProps: true }),
    new CaptureConsole({
      levels: ['error']
    })
  ],
  environment: env.VUE_APP_ENVIRONMENT,
  release: version.Version
})

store.watch(state => state.user.username, username => Sentry.setUser({ email: username }))
store.watch(state => state.accreditation.currentAccreditationId, accreditation => Sentry.setTag('accreditation', decodeId(accreditation)))