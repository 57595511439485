<template>
  <div class="o-csr">
    <div class="row justify-content-center">
      <div class="col-11">
        <div class="o-csr__row">
          <div class="o-csr__col o-csr__col-bold" v-html="identifiedAsText"></div>
          <div class="o-csr__col o-csr__col-sep">|</div>
          <div class="o-csr__col">
            <button class="o-csr__link" v-on:click="goToSACProfile">{{ $t('csrMyAccount') }}</button>
          </div>
          <div class="o-csr__col o-csr__col-sep">|</div>
          <div class="o-csr__col">
            <button class="o-csr__link" @click="leaveSACAccount">{{ $t('csrLeaveThisAccount') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Urls, Consts } from '@/_helpers'

export default {
  components: {},
  data() {
    return {
      identifiedAs: this.$store.getters[Consts.GETTER_USER_EMAILSAC]
    }
  },
  computed: {
    identifiedAsText() {
      return this.$t('csrIdentifiedAs').replace('$1', '<a href="mailto:' + this.identifiedAs + '">' + this.identifiedAs + '</a>')
    }
  },
  methods: {
    leaveSACAccount: function() {
      this.$store.dispatch(Consts.ACTION_USER_SETUSERSACEMAIL, null)
      this.$store.commit(Consts.ACTION_ACCREDITATION_SELECT, null)
      this.$router.push(Urls.localizedUrl(Urls.SELECT_ACCREDITATIONS))
    },
    goToSACProfile: function() {
      this.$router.push(Urls.localizedUrl(Urls.USER_PROFILE_SAC))
    }
  }
}
</script>

<style lang="scss">
@import '../../utilities/config';
.o-csr {
  background-color: theme-color('primary');
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  text-align: center;
  color: gray('white');
  font-size: 14px;
  overflow: hidden;

  &__row {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__col {
    &-sep {
      margin: 0 0.5em;
      display: none;
    }
    &-bold {
      font-weight: 400;
    }
  }

  a,
  &__link {
    background: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    padding: 0;
    user-select: none;
    white-space: normal;

    color: gray('white');
    text-decoration: underline;

    &:hover {
      color: gray('white');
    }
  }
}
@media screen and (min-width: breakpoint-min('sm')) {
  .o-csr {
    &__row {
      height: 40px;
      flex-direction: row;
    }
    &__col {
      &-sep {
        display: block;
      }
    }
  }
}
</style>
