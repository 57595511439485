export default {
  getValue(key, value = null) {
    const result = localStorage.getItem(key)
    if (result) return result
    value && localStorage.setItem(key, value)
    return value
  },
  getObject(key) {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      //
    }
  },
  setObject(key, value) {
    if (value != null) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.removeItem(key)
    }
  },
  setValue(key, value) {
    if (value != null) {
      localStorage.setItem(key, value)
    } else {
      localStorage.removeItem(key)
    }
  }
}
