const state = {
  multibuilding: null
}

const mutations = {
  load(state, features) {
    state.multibuilding = features.includes('multibuilding')
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
