import { userService } from '@/_services'
import storage from '@/_helpers/storage'
import { Consts } from '@/_helpers'

const MUTATION_SETUSER = 'setUser'
const MUTATION_SETUSERNAME = 'setUsername'
const MUTATION_SET_REMEMBER_ME = 'setRememberMe'
const MUTATION_SETSACEMAIL = 'setSACEmail'

export default {
  setUserSACEmail({ commit }, email) {
    commit(MUTATION_SETSACEMAIL, email)
  },
  async login({ state, commit }, { username, password, rememberMe }) {
    const user = await userService.login(username, password, state.deviceId)
    if (user.token) {
      commit(MUTATION_SETUSER, user)
      commit(MUTATION_SETUSERNAME, username)
      commit(MUTATION_SET_REMEMBER_ME, rememberMe)
    }
  },
  logout({ state, commit }) {
    console.log('logging out')
    commit(MUTATION_SETSACEMAIL, null)
    commit(MUTATION_SETUSER, null)

    if (!state.rememberMe)
      commit(MUTATION_SETUSERNAME, null)
  },
  gettoken() {
    return currentRefreshCall ||
      storage.getObject(Consts.LOCAL_STORAGE_USER)?.token
  },
  refreshtoken({ state, commit, dispatch }) {
    if (currentRefreshCall) return currentRefreshCall

    console.log('refreshing token')
    currentRefreshCall = batchedRefresh({ state, commit, dispatch})
      .finally(() => currentRefreshCall = null)

    return currentRefreshCall
  }
}

let currentRefreshCall
async function batchedRefresh({ state, commit, dispatch }) {
  try {
    const { token, refreshToken } = storage.getObject(Consts.LOCAL_STORAGE_USER)
    const user = await userService.refreshtoken(token, refreshToken, state.deviceId)

    commit(MUTATION_SETUSER, user)
    return user.token
  } catch (e) {
    await dispatch('logout')
  }
}
