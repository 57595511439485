export const Validations = {
  validePassword,
  hasNumber,
  hasLowerCase,
  hasUpperCase,
  isEmail
}

function validePassword(password) {
  return password.length > 6 && this.hasLowerCase(password) && this.hasUpperCase(password) && this.hasNumber(password)
}

function hasNumber(myString) {
  return /\d/.test(myString)
}

function hasLowerCase(str) {
  return str.toUpperCase() != str
}

function hasUpperCase(str) {
  return str.toLowerCase() != str
}

function isEmail(str) {
  return RegExp('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}').test(str)
}
