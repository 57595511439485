const overrides = window.gcr_env || {}

const env = {
  VUE_APP_ENVIRONMENT: overrides.VUE_APP_ENVIRONMENT || process.env.VUE_APP_ENVIRONMENT,
  VUE_APP_I18N_LOCALE: overrides.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_LOCALE,
  VUE_APP_I18N_FALLBACK_LOCALE: overrides.VUE_APP_I18N_FALLBACK_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  VUE_APP_ROOT_API: overrides.VUE_APP_ROOT_API || process.env.VUE_APP_ROOT_API,
  VUE_APP_SENTRY_DSN: overrides.VUE_APP_SENTRY_DSN || process.env.VUE_APP_SENTRY_DSN,
  VUE_APP_NAV_URL: overrides.VUE_APP_NAV_URL || process.env.VUE_APP_NAV_URL
}

export default env
