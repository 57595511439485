<template>
  <div id="app">
    <customer-services-ribbon v-if="isCustomerServices && currentAccreditation" />

    <alert :class="{ 'm-alert--loggedout': !currentAccreditation, 'm-alert--cs': isCustomerServices }"></alert>
    <navigation v-if="currentAccreditation" :class="{ 'o-nav--cs': isCustomerServices }" />
    <navigation-logged-out v-else />
    <div :class="{ 'container-fluid app-logged-in': currentAccreditation, 'app-logged-in--cs': isCustomerServices }">
      <router-view data-test-id="router-view" :key="$route.path" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CustomerServicesRibbon from '@/components/organism/CustomerServicesRibbon'
import Navigation from '@/components/organism/Navigation'
import NavigationLoggedOut from '@/components/organism/NavigationLoggedOut'
import Alert from '@/components/molecules/Alert'
import { Urls, Consts } from './_helpers'

require('./utilities/scaffolding')
require('./utilities/grid')
require('./utilities/tooltip')

export default {
  components: { CustomerServicesRibbon, Navigation, NavigationLoggedOut, Alert },
  computed: {
    ...mapGetters({
      isLogged: Consts.GETTER_USER_ISLOGGED,
      emailSAC: Consts.GETTER_USER_EMAILSAC,
      currentAccreditation: Consts.GETTER_ACCREDITATION_CURRENT_ID
    }),
    isCustomerServices() {
      return this.isLogged && this.emailSAC
    }
  },
  watch: {
    async isLogged(newValue, oldValue) {
      if (!newValue && oldValue) {
        await this.$router.push(Urls.localizedUrl(Urls.LOGIN))
        this.$store.commit(Consts.ACTION_ACCREDITATION_SELECT, null)
        await this.$apollo.provider.defaultClient.resetStore()
      }
    }
  }
}
</script>

<style>
#app {
  max-width: 100vw;
  overflow-x: hidden;
}
</style>
