import { Consts } from '@/_helpers/consts'
import storage from '@/_helpers/storage'
import actions from '@/_store/modules/user.actions'

const state = {
  user: null,
  username: null,
  rememberMe: false,
  deviceId: null,
  userSACEmail: null
}

const getters = {
  isLogged: state => !!state.user,
  isSAC: state => !!state.userSACEmail,
  emailSAC: state => state.userSACEmail
}

const mutations = {
  setUser(state, user) {
    state.user = user
    storage.setObject(Consts.LOCAL_STORAGE_USER, user)
  },
  setUsername(state, username) {
    state.username = username
    storage.setValue(Consts.LOCAL_STORAGE_USERNAME, username)
  },
  setRememberMe(state, rememberMe) {
    state.rememberMe = rememberMe
    storage.setObject(Consts.LOCAL_STORAGE_REMEMBER_ME, rememberMe)
  },
  setSACEmail(state, email) {
    state.userSACEmail = email
    storage.setValue(Consts.LOCAL_STORAGE_SACEMAIL, email)
  },
  load(state) {
    state.user = storage.getObject(Consts.LOCAL_STORAGE_USER)
    state.username = storage.getValue(Consts.LOCAL_STORAGE_USERNAME)
    state.rememberMe = storage.getObject(Consts.LOCAL_STORAGE_REMEMBER_ME)
    state.userSACEmail = storage.getValue(Consts.LOCAL_STORAGE_SACEMAIL)
    state.deviceId = storage.getValue(Consts.LOCAL_STORAGE_DEVICE, 'portail-' + new Date().getTime())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
