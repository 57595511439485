import i18n from '@/i18n'

export const Urls = {
  localizedUrl,
  oppositeLocalizedUrl,
  oppositeCurrentUrl,
  LOGIN: 'LOGIN',
  DASHBOARD: 'DASHBOARD',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  USER_PROFILE: 'USER_PROFILE',
  USER_PROFILE_SAC: 'USER_PROFILE_SAC',
  SELECT_ACCREDITATIONS: 'SELECT_ACCREDITATIONS',
  CREATE_NEW_PROJECT: 'CREATE_NEW_PROJECT',
  EDIT_NEW_PROJECT: 'EDIT_NEW_PROJECT',
  PROJECT_DETAIL: 'PROJECT_DETAIL',
  PROJECT_DETAILS: 'PROJECT_DETAILS',
  REGISTER_PROJECT: 'REGISTER_PROJECT',
  EDIT_BUILDING: 'EDIT_BUILDING',
  PROJECTS: 'PROJECTS',
  INVOICE: 'INVOICE',
  PAY_PROJECT: 'PAY_PROJECT',
  INSPECTIONS: 'INSPECTIONS',
  EDIT_BUILDING_CONSTRUCTION_SCHEDULE: 'EDIT_BUILDING_CONSTRUCTION_SCHEDULE',
  FORM_LIST: 'FORM_LIST',
  FORM_LIST_ENGLISH: 'FORM_LIST_ENGLISH',
  ACCREDITATION_DETAIL: 'ACCREDITATION_DETAIL',
  ACCREDITATION_RENEW: 'ACCREDITATION_RENEW',
  BUYERS_INFO: 'BUYERS_INFO'
}

const urls = {
  fr: {
    [Urls.DASHBOARD]: '/fr',
    [Urls.LOGIN]: '/fr/connection',
    [Urls.CONFIRM_EMAIL]: '/fr/confirmer-courriel',
    [Urls.FORGOT_PASSWORD]: '/fr/mot-de-passe-oublie',
    [Urls.RESET_PASSWORD]: '/fr/reinitialiser-mot-de-passe',
    [Urls.USER_PROFILE]: '/fr/profile',
    [Urls.USER_PROFILE_SAC]: '/fr/profile-sac',
    [Urls.SELECT_ACCREDITATIONS]: '/fr/choisir-accreditation',
    [Urls.INVOICE]: '/fr/factures',
    [Urls.PROJECTS]: '/fr/projets',
    [Urls.CREATE_NEW_PROJECT]: '/fr/projets/creer-nouveau-projet',
    [Urls.EDIT_NEW_PROJECT]: '/fr/projets/editer-nouveau-projet',
    [Urls.PROJECT_DETAILS]: '/fr/projets/details-projet',
    [Urls.EDIT_BUILDING_CONSTRUCTION_SCHEDULE]: '/fr/projets/batiment/editer-calendrier-construction',
    [Urls.PAY_PROJECT]: '/fr/projets/payer-projet',
    [Urls.REGISTER_PROJECT]: '/fr/projets/enregistrer-projet',
    [Urls.EDIT_BUILDING]: '/fr/projets/editer-batiment',
    [Urls.INSPECTIONS]: '/fr/inspections',
    [Urls.FORM_LIST]: '/fr/formulaires',
    [Urls.FORM_LIST_ENGLISH]: '/fr/formulaires-en',
    [Urls.ACCREDITATION_DETAIL]: '/fr/accreditation',
    [Urls.ACCREDITATION_RENEW]: '/fr/accreditation/renouveler-accreditation',
    [Urls.BUYERS_INFO]: '/fr/projets/acheteurs',
  },
  en: {
    [Urls.DASHBOARD]: '/en',
    [Urls.LOGIN]: '/en/login',
    [Urls.CONFIRM_EMAIL]: '/en/confirm-email',
    [Urls.FORGOT_PASSWORD]: '/en/forgot-password',
    [Urls.RESET_PASSWORD]: '/en/reset-password',
    [Urls.USER_PROFILE]: '/en/profile',
    [Urls.USER_PROFILE_SAC]: '/en/profile-sac',
    [Urls.SELECT_ACCREDITATIONS]: '/en/select-accreditation',
    [Urls.INVOICE]: '/en/invoices',
    [Urls.PROJECTS]: '/en/projects',
    [Urls.CREATE_NEW_PROJECT]: '/en/projects/create-new-project',
    [Urls.EDIT_NEW_PROJECT]: '/en/projects/edit-new-project',
    [Urls.PROJECT_DETAILS]: '/en/projects/project-details',
    [Urls.EDIT_BUILDING_CONSTRUCTION_SCHEDULE]: '/en/projects/building/edit-construction-schedule',
    [Urls.PAY_PROJECT]: '/en/projects/pay-project',
    [Urls.REGISTER_PROJECT]: '/en/projects/register-project',
    [Urls.EDIT_BUILDING]: '/en/projects/edit-building',
    [Urls.INSPECTIONS]: '/en/inspections',
    [Urls.FORM_LIST]: '/en/forms-fr',
    [Urls.FORM_LIST_ENGLISH]: '/en/forms',
    [Urls.ACCREDITATION_DETAIL]: '/en/accreditation',
    [Urls.ACCREDITATION_RENEW]: '/en/accreditation/renew-accreditation',
    [Urls.BUYERS_INFO]: '/en/projects/buyers',
  }
}

function localizedUrl(key) {
  return urls[i18n.locale][key]
}

function oppositeLocalizedUrl(key) {
  return urls[i18n.locale === 'en' ? 'fr' : 'en'][key]
}

function oppositeCurrentUrl(route) {
  if (route.name) {
    const querystring = route.fullPath.replace(route.path, '')
    return oppositeLocalizedUrl(route.name) + querystring
  }
  return oppositeLocalizedUrl(Urls.DASHBOARD)
}
